<template>
  <v-row>
    <v-col>
      <v-card class="mx-auto" max-width="800">
        <v-card-title>
          <v-icon large left> mdi-twitter </v-icon>
          <span class="text-h6 font-weight-light"
            >LAPORAN BUDAYA KESELAMATAN K3</span
          >
        </v-card-title>
        <v-form @submit.prevent="add">
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="4">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.tgl_lapor"
                      label="Tanggal Lapor"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.tgl_lapor"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="11" sm="5">
                <v-menu
                  ref="menu"
                  v-model="timer"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.time"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timer"
                    v-model="form.time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(form.time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="form.laporan">
                  <template v-slot:label>
                    <div>Apakah masalah yang akan dilaporkan</div>
                  </template>
                  <v-radio value="IKP">
                    <template v-slot:label>
                      <div>
                        IKP
                        <small class="success--text"
                          >(Kejadian nyaris cedera, kejadian tidak nyarus
                          cedera, kejadian tidak diharapkan dan kejadian
                          sentibel)</small
                        >
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="IBK">
                    <template v-slot:label>
                      <div>
                       Insiden Budayaan Keselamatan
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.peristiwa"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Jelaskan secara singkat peristiwa yang terjadi"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.lokasi"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Jelaskan secara singkat lokasi dimana peristiwa terjadi"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.tindakan"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Jelaskan secara singkat hal apa yang sudah dilakukan"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.aman"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Apakah anda merasa AMAN bekerja di Rumah Sakit ?"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.nyaman"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Apakah anda merasa NYAMAN bekerja di Rumah Sakit ?"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  autocomplete=""
                  v-model="form.tekanan"
                  auto-grow
                  outlined
                  rows="2"
                  row-height="15"
                  label="Apakah selam Anda bekerja ada TEKANAN dari atasan ?"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-col>
              <v-btn type="submit" color="success">SIMPAN</v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HelloWorld from "../components/HelloWorld";

export default {
  name: "Home",

  components: {
    HelloWorld,
  },
  data: () => ({
    menu2: false,
    timer: false,
    ikp: false,
    ibk: false,
    form: {
      peristiwa: "",
      lokasi: "",
      tindakan: "",
      aman: "",
      nyaman: "",
      tekanan: "",
      laporan: null,
      tgl_lapor: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    time: null,
    },
  }),
  mounted() {
   this.timeow();
  },
  methods: {
    add() {
      axios.post("K3/store", this.form).then((res) => {
        // this.load();
        // this.form.name = "";
        console.log(res);
      });
    }, 
    timeow () { 
      var currentdate = new Date(); 
      var timenow = ("0" + currentdate.getHours()).slice(-2) + ":"+ ("0" +currentdate.getMinutes()).slice(-2);
      console.log(timenow);
      // ("0" + currentHours).slice(-2);
      this.form.time = timenow;

     }
  }
  
};
</script>
